<template>
    <div>
      <v-container>
        <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
        <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
        
          <br> 
        
        <template>
          <v-row dense>
            <v-col cols="auto" style="margin-left: auto; margin-right: auto">
                <v-row dense>
                    <label for="codArt">&nbsp;<b>Introduce el código de los articulos a imprimir</b></label>
                
                </v-row>
                <v-row>
                    <br>
                </v-row>  
                <!--
                <v-row>
                    <v-text-field
                    class="mb-n7 no-arrows" outlined dense
                    type="text"
                    v-model="codArt"
                    label="Articulos"
                    >
                  
                    </v-text-field>
                </v-row>
                -->
            </v-col>
            
          </v-row>

          <v-row v-if="!mostrado" dense style="justify-content:center">
            <div v-for="(fila, indexFila) in carteles" :key="indexFila" class="fila">
              <div v-for="(articulo, indexArticulo) in fila" :key="indexArticulo" class="cartel">
                <v-text-field
                  v-model="articulo.codart"
                  placeholder="Código de árticulo"
                ></v-text-field>
              </div>
            </div>
          </v-row>


          <v-row v-if="!loading && mostrado" justify="center">
            <v-col cols="12" sm="11" md="10" lg="9" xl="5">
                <pdf  
                    :src="'data:application/pdf;base64,'+ doc" 
                    :page="1" 
                    style="width: 100%; border-style:solid; border-width: 1px; border-color: silver">
                </pdf>
                
            </v-col>

        </v-row>
        </template>
        
        <!-- </div> -->
      <br>
      <br>
      <v-row dense >
        <v-col cols="6" xs6 style="text-align: right;">
          <v-btn
            color="primary"
            :loading="loading"
            @click="onClickSaveData"
          >
            Aceptar
          </v-btn>
        </v-col>
        <v-col cols="6" xs6 style="text-align: left;">
          <v-btn
            color="primary"
            :loading="loading"
            @click="downloadPDF">
            descargar
          </v-btn>
        </v-col>
      </v-row>
     
      <!--
      <a :href="'data:application/pdf;base64,' + doc" download="documento.pdf">
              Descargar PDF
            </a>
            -->
        
  
      </v-container>
    </div>
  </template>
  
  <style>
  /* Chrome, Safari, Edge, Opera */
  .no-arrows>div>div>div>input::-webkit-outer-spin-button,
  .no-arrows>div>div>div>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  
  /* Firefox */
  .no-arrows>div>div>div>input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  }
  
  .cartel {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    flex: 1;
  
  }

  
  </style>

  <script>
    import axios from "axios";
    import { mapState } from "vuex";
    import pdf from 'vue-pdf';

    export default {
      components: {
        pdf
    },
    computed: {
        ...mapState(['empId', 'perId', 'perAux', 'esEmpleado', 'urlRaiz'])
    },
    data: () => ({
        loading: true,
        mostrado: false,
        carteles: Array.from({ length: 2 }, () =>
        Array(7).fill({ codart: '' }).map(item => ({ ...item }))
        ),
        codart:"",
        codArt: "", 
        controllerParameters: null,
        DirId: "",
        EmpId:"",
        doc: null,
    
    
    }),
    mounted() {
        this.loadData()
        this.loading = false;
        },
       
    methods: {
        postDataToApi() {
        this.loading = true;

        // Asignar valores a controllerParameters
        this.controllerParameters = {
            Articulos: {
            Codart: this.codArt,
            },
            carteles: this.carteles,
            DirId: this.$store.state.dir.DirId,
            EmpId: this.$store.state.emp.EmpId
        };
        /*
        const username = "andres.pinilla";
        const password = "1234567";
        
        const credentials = `${username}:${password}`;
        const encoder = new TextEncoder();
        const base64Credentials = btoa(String.fromCharCode.apply(null, encoder.encode(credentials)));
        const authorizationHeader = "Basic " + base64Credentials;
        */
        const AuthToken = localStorage.getItem('token');
        return new Promise((resolve) => {
        axios({
            method: "POST",
            "url": this.urlRaiz + "/api/carteleria",
            data: JSON.stringify(this.controllerParameters),
            headers: {"content-type": "application/json", "Authorization":  AuthToken}
        })
        .then(result => {
            const item = result.data;
            setTimeout(() => {
                  this.loading = false;
                  resolve({
                    item
                  })
              }, 1000)
            })
            .catch(error => {
              if(error != null) {
                  this.loading = false;
                  alert('Error al imprimir carteles');
              }
            });
          })
        },
        onClickSaveData() {
            this.postDataToApi()
            .then(data => {
          console.log(data.item)
          this.doc = data.item
          this.mostrado = true
          if(this.doc=="Hay uno o mas cárteles sin código"){
            alert('Hay uno o mas cárteles sin código');
            this.mostrado = false
          }
            })
            .catch(error => {
            console.error('An error occurred:', error.message);
            alert('Error al imprimir el carteles:' + error.message);
        });
        },
        loadData(){
        /*
          this.postDataToApi()
        .then(data => {
          console.log(data.item)
          this.doc = data.item
        });*/
      },
      downloadPDF() {
        const linkSource = `data:application/pdf;base64,${this.doc}`;
        const downloadLink = document.createElement("a");
        const fileName = "carteleria.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.mostrado = false
        this.carteles = Array.from({ length: 2 }, () =>
            Array(7).fill({ codart: '' }).map(item => ({ ...item }))
        );
        
    }
   
    

    }
  }
    

</script>
